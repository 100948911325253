import { api } from "@/services";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { useConfig } from "@/app/composables/useConfig";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useSportsdata } from "@/sportsbook/composables/useSportsdata";
import { useVirtualGamesService } from "@/virtual-games/composables/useVirtualGamesService";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import { useNavigation } from "../composables/useNavigations";
import { useTimeServer } from "../composables/useTimeServer";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";
import { useLogoBanner } from "../composables/useLogoBanner";

const { setCurrencies } = useCurrencies();
const { setUser, isAuthenticated } = useUserService();
const { setSystemSettings } = useConfig();
const { setSports } = useSportsdata();
const { setVirtualGames } = useVirtualGamesService();
const { setFreeBets } = useFreeBets();
const { setBanners, setLogo } = useLogoBanner();
const { setNavigation } = useNavigation();
const { setTimeServer } = useTimeServer();
const { setReferAndEarn } = useReferAndEarn();

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let pingCount = 0;
async function pingServer() {
  const query = [
    "systemSettings",
    "currencies",
    "sports",
    "userAccount",
    "freeBets",
    "virtualGames",
  ];

  pingCount += 1;

  if (query.length > 0) {
    const data = await api.utils.ping({ query });

    if (data?.systemSettings) {
      setSystemSettings(data.systemSettings);
    }
    if (data?.logo) {
      setLogo(data.logo);
    }
    if (data?.banners) {
      setBanners(data.banners);
    }
    if (data?.currencies) {
      setCurrencies(data.currencies);
    }
    if (data?.userAccount) {
      setUser(data.userAccount);
    }
    if (data?.sports) {
      setSports(data.sports);
    }
    if (data?.virtualGames) {
      setVirtualGames(data.virtualGames);
      if (data?.freeBets) {
        setFreeBets(data.freeBets);
      }
    }
    if (data?.navigation) {
      setNavigation(data?.navigation);
    }
    if (data?.now) {
      setTimeServer(data?.now);
    }

    if (data?.referAndEarn) {
      setReferAndEarn(data.referAndEarn);
    }
  }
}

async function initPingService() {
  await pingServer();

  if (import.meta.env.VITE_PING === "true") {
    setInterval(async () => {
      await pingServer();
    }, 3000);
  }
}

export { getCookie, pingServer, initPingService };
