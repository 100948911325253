<script setup>
import { computed, onMounted, watch } from "vue";

import { useBetStore } from "../../../store";
import ProgressLoader from "@/app/components/ui/ProgressLoader.vue";
import CasinoPanel from "@/casino/components/CasinoPanel.vue";
import ProviderList from "@/casino/components/ProviderList.vue";
import CategoryGameList from "@/casino/components/CategoryGameList.vue";
import GameList from "@/casino/components/GameList.vue";
import { useCasinoService } from "@/casino/composables/useCasinoService.js";
import { router } from "@/app/router";
import { brands } from "@/app/utils/brands";
import BaseSlider from "@/app/components/ui/BaseSlider.vue";
import Banner from "@/app/components/Banner.vue";
import { useLogoBanner } from "@/app/composables/useLogoBanner";

const store = useBetStore();
const items = [1, 2, 3];

const selectedProviders = computed(() => store.selectedProviders);

const { casinoBannerImg, casinoBannerMblImg } = brands[import.meta.env.VITE_APP_BRAND];
const { banners } = useLogoBanner();

const { state, providers, categories, loading, initCasino } = useCasinoService();

watch(
  selectedProviders,
  async (newValue) => {
    const filteredObj = Object.keys(newValue)
      .filter((key) => newValue[key])
      .reduce((res, key) => {
        res[key] = newValue[key];
        return res;
      }, {});
    await initCasino(filteredObj);
  },
  { deep: true }
);

onMounted(async () => {
  await initCasino();
});

const spliderOptions = {
  perPage: 1,
  pagination: false,
  type: "loop",
  autoplay: "play",
  arrows: false,
  interval: 10000,
};
</script>

<template>
  <article class="casino-view">
    <div class="banner">
      <Banner :imgs="banners?.casino" />
    </div>
    <ProgressLoader :loading="loading" />
    <CasinoPanel />
    <ProviderList
      v-if="
        state.category.id == 0 &&
        providers.length > 1 &&
        (Object.keys(state.selectedProviders).length == 0 ||
          router.currentRoute.value.href == '#/casino/lobby')
      "
      v-memo="[providers.length]"
    />

    <template v-if="state.category.id === 0 && router.currentRoute.value.href == '#/casino/lobby'">
      <CategoryGameList v-for="(category, index) in categories" :key="index" :category="category" />
    </template>

    <GameList
      v-else-if="
        (state.category.id > 0 ||
          (state.category.id == 0 && Object.keys(state.selectedProviders).length > 0)) &&
        !loading
      "
    />
  </article>
</template>

<style scoped>
.banner-img {
  width: 100%;
}
.casino-view {
  width: 100%;
  min-height: 70vh;
}

.note {
  margin: 20px;
}
</style>
