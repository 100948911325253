<script setup>
import { onMounted, ref } from "vue";
import SelectItem from "../../SelectItem.vue";
import { api } from "@/services/index.js";
import { t } from "@/app/composables/useI18n";
import { useCurrencies } from "@/app/composables/useCurrencies";

const { getIconByTicker } = useCurrencies();

const loading = ref(false);
const showMoreButton = ref(true);
const transactions = ref([]);
const selectedStatus = ref({});
const selectedWallet = ref({});
const selectedType = ref({});
const wallets = ref([]);
const rowsPerPage = 5;
let currentPage = 1;

const statuses = ref([
  {
    value: null,
    title: t("finance.transactions_all"),
  },
  {
    value: "initiated",
    title: "Initiated",
  },
  {
    value: "success",
    title: "Success",
  },
  {
    value: "cancelled",
    title: "Cancelled",
  },
  {
    value: "error",
    title: "Error",
  },
]);

const types = ref([
  {
    value: null,
    title: t("finance.transactions_all"),
  },
  {
    value: "deposit",
    title: "Deposit",
  },
  {
    value: "withdrawal",
    title: "Withdrawal",
  },
  {
    value: "adjustment",
    title: "Adjustment",
  },
]);

selectedStatus.value = selectedType.value = {
  value: null,
  title: t("finance.transactions_all"),
};
const allWalletOption = {
  id: null,
};
selectedWallet.value = allWalletOption;

async function loadTransactions() {
  loading.value = true;
  const pageResult = await api.finance.userTransactions({
    walletId: selectedWallet.value.id,
    status: selectedStatus.value.value,
    type: selectedType.value.value,
    paginator: {
      cp: currentPage,
      rpp: rowsPerPage,
    },
  });
  loading.value = false;

  if (pageResult.transactions.length > 0) {
    showMoreButton.value = true;
    if (currentPage === 1) {
      transactions.value = pageResult.transactions;
    } else {
      transactions.value.push(...pageResult.transactions);
    }
  } else {
    showMoreButton.value = false;
  }
}

function loadNextTransactionsPage() {
  currentPage++;
  loadTransactions();
}

async function loadUserWallets() {
  wallets.value = await api.finance.userWallets();
}

function getWallet(id) {
  return wallets.value.find((wallet) => wallet.id === id);
}

function getWalletTitle(wallet) {
  if (wallet.id == null) {
    return t("finance.transactions_all");
  }

  return `${wallet.currency.ticker}: ${ucfirst(wallet.type)}`;
}

function selectWallet(wallet) {
  selectedWallet.value = wallet;
  currentPage = 1;
  transactions.value = [];
  loadTransactions();
}

function selectStatus(status) {
  selectedStatus.value = status;
  currentPage = 1;
  transactions.value = [];
  loadTransactions();
}

function selectType(type) {
  selectedType.value = type;
  currentPage = 1;
  transactions.value = [];
  loadTransactions();
}

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

onMounted(() => {
  loadUserWallets();
  loadTransactions();
});
</script>

<template>
  <div class="transaction">
    <div class="transaction-filters">
      <div class="transaction-filter">
        <div>{{ t("account.wallet") }}</div>
        <div class="select-filter">
          <SelectItem :value="getWalletTitle(selectedWallet)">
            <template #header>
              <div @click="selectWallet(allWalletOption)" :key="allWalletOption.id">
                {{ getWalletTitle(allWalletOption) }}
              </div>
              <div v-for="wallet in wallets" @click="selectWallet(wallet)" :key="wallet.id">
                {{ getWalletTitle(wallet) }}
              </div>
            </template>
          </SelectItem>
        </div>
      </div>
      <div class="transaction-filter">
        <div>{{ t("finance.status") }}</div>
        <div class="select-filter">
          <SelectItem :value="selectedStatus.title">
            <template #header>
              <div v-for="status in statuses" @click="selectStatus(status)" :key="status.title">
                {{ status.title }}
              </div>
            </template>
          </SelectItem>
        </div>
      </div>
      <div class="transaction-filter">
        <div>{{ t("finance.type") }}</div>
        <div class="select-filter">
          <SelectItem :value="selectedType.title">
            <template #header>
              <div v-for="type in types" @click="selectType(type)" :key="type.title">
                {{ type.title }}
              </div>
            </template>
          </SelectItem>
        </div>
      </div>
    </div>

    <div class="transaction-card" v-for="transaction in transactions" :key="transaction.id">
      <div class="card-header">
        <div class="header-left">
          <!-- <div>#{{ transaction.id }}:</div> -->
          <img src="" alt="" />
          <div>&nbsp;{{ ucfirst(transaction.type) }}</div>
        </div>
        <div :class="`status ${transaction.status}`">{{ ucfirst(transaction.status) }}</div>
      </div>
      <div class="card-body">
        <div class="amount-titles">
          <div>{{ t("finance.amount") }}</div>
          <div v-if="transaction.bonusName">{{ t("finance.bonus") }}</div>
          <div>{{ t("account.dob_date") }} & {{ t("finance.time") }}</div>
          <div v-if="transaction.desc !== null && transaction.desc !== ''">
            {{ t("finance.description") }}
          </div>
          <div v-if="transaction.message !== null">{{ t("finance.message") }}</div>
          <div v-if="transaction.txid !== null">TX</div>
        </div>
        <div class="card--amounts">
          <div>
            <img class="img-icon" :src="getIconByTicker(transaction?.currency?.ticker)" alt="" />
            {{ transaction.amount }}
          </div>
          <div v-if="transaction.bonusName">{{ transaction.bonusName }}</div>
          <div>{{ transaction.createdAt }}</div>
          <div v-if="transaction.desc !== null && transaction.desc !== ''">
            {{ transaction.desc }}
          </div>
          <div v-if="transaction.message !== null">{{ transaction.message }}</div>
          <div v-if="transaction.txid !== null" class="txid">{{ transaction.txid }}</div>
        </div>
      </div>
    </div>

    <button
      class="btn-g"
      :disabled="loading"
      @click="loadNextTransactionsPage()"
      v-if="showMoreButton"
    >
      <span v-if="loading">{{ t("finance.loading") }}</span>
      <span v-else>{{ t("finance.more_transactions") }}</span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.img-icon {
  width: 16px;
  height: 16px;
  float: right;
}

.txid {
  width: 260px;
  overflow-y: auto;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--be-primary-btn);
  margin: 12px 0px;
}

.transaction {
  margin: 16px;
}

.select-item {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background: var(--be-small-card-bg);
  display: flex;
  justify-content: space-between;
}

.items {
  border-radius: 8px;
  background: #0d0c2c;
  padding: 8px;
  background: var(--be-small-card-bg);
  margin-top: 10px;
}

.transaction-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .transaction-filter {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .select-filter {
    width: 132px;
  }

  @media screen and (max-width: 500px) {
    .select-filter {
      width: 113px;
    }
  }
}

.transaction-card {
  margin-top: 1em;
  border-radius: 8px;
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 10px 16px;
  // display: flex;
  // justify-content: space-between;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .header-left {
      display: flex;
    }

    .status {
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 6px 8px;
    }

    .success {
      color: #3178d3;
      background: rgba(55, 111, 229, 0.3);
    }
    .initiated {
      background: rgba(72, 72, 72, 0.3);
      color: rgba(255, 255, 255, 0.5);
    }
    .error {
      background: rgba(148, 0, 0, 0.3);
      color: #940000;
    }
    .cancelled {
      background: rgba(147, 52, 1, 0.3);
      color: #943e00;
    }
  }
  .card-body {
    display: flex;
    justify-content: space-between;
  }

  .amount-titles {
    display: flex;
    flex-direction: column;
    gap: 10px 0;

    div {
      white-space: nowrap;
    }
  }
  .card--amounts {
    display: flex;
    word-break: break-word;
    flex-direction: column;
    align-items: end;
    gap: 10px 0;
    margin-left: 10px;

    div {
      text-align: right;
    }
  }
  .card-address {
    display: flex;
    gap: 0 5px;
  }
}
</style>
