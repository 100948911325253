<script setup>
import { computed } from "vue";
import moment from "moment";
import ActiveBlock from "../CasinoBonusSection/ActiveBlock.vue";
import Transaction from "./Transaction.vue";
import MoneyTransferSelection from "./MoneyTransferSelection.vue";
import { api } from "@/services";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { useBetStore } from "/store";
import { t } from "@/app/composables/useI18n";
import { useUserService } from "@/user-account/composables/useUserService";
import { useGameHelper } from "@/casino/composables/useGameHelper";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import { usePromotion } from "@/app/composables/usePromotion";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { useConfig } from "@/app/composables/useConfig";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);

const { systemSettings } = useConfig();
const { fundWallets, activeWallet, user, selectWallet } = useUserService();
const { getIconByTicker } = useCurrencies();
const { freeBets } = useFreeBets();
const { isGameSession } = useGameHelper();
const { getActiveBonus, activeBonus } = usePromotion();

const store = useBetStore();

const tabs = [
  { value: "balance", title: t("finance.balance"), icon: "balance" },
  { value: "deposit", title: t("finance.deposit"), icon: "deposit" },
  { value: "withdraw", title: t("finance.withdraw"), icon: "withdraw" },
  { value: "transactions", title: t("finance.transactions"), icon: "transaction" },
];

const selectedTab = computed(() => {
  return store.getWalletTab;
});

function onTabClick(tab) {
  store.setWalletTab(tab);
}

function navigatePersonalInfo() {
  store.setSection("account");
  store.setAccountTab("personalInfo");
}

async function setActiveWallet(wallet) {
  if (isGameSession.value) {
    return;
  }
  const wallets = await api.finance.lastSelectedId({
    walletId: wallet.id,
  });

  selectWallet(wallet.id);
}

function getActiveBonusExpireDate(bonus) {
  if (bonus?.expirationDate !== null && bonus?.expirationDate !== "") {
    return `${t("casino.expired_at")} ${moment(bonus?.expirationDate).format("HH:mm DD/MM/YYYY")}`;
  } else {
    return null;
  }
}

const depositPersonalInfoRequirement = computed(() => {
  return (
    systemSettings.value.personalInformationRequiredFor === "deposit" && user.value.kycLevel < 1
  );
});

const withdrawPersonalInfoRequirement = computed(() => {
  return (
    systemSettings.value.personalInformationRequiredFor === "withdrawal" && user.value.kycLevel < 1
  );
});

getActiveBonus();
</script>

<template>
  <div>
    <div class="wallets">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="`wallet ${selectedTab === tab.value && 'active-wallet'}`"
        @click="onTabClick(tab.value)"
      >
        <BaseIcon
          class="wallet-tab"
          :name="tab.icon"
          size="18"
          :color="
            selectedTab === tab.value
              ? 'var(--be-primary-btn-text-label)'
              : 'var( --be-primary-text-color-opacity)'
          "
        />
        <div>
          {{ tab.title }}
        </div>
      </div>
    </div>
    <div v-if="selectedTab === 'balance'" class="balance">
      <div>
        <div class="wallet-balance">
          <div v-for="wallet in fundWallets" :key="wallet.id" class="balance-row">
            <div>
              <label class="container">
                <input
                  type="radio"
                  :checked="activeWallet.id === wallet.id"
                  @click="() => setActiveWallet(wallet)"
                />
                {{ Number(wallet?.balance)?.toFixed(wallet?.currency?.places) }}
                <span class="checkmark" />
              </label>
            </div>
            <div class="currency-balance">
              <div>{{ wallet?.currency?.name }}</div>
              <img :src="getIconByTicker(wallet?.currency?.ticker)" class="currency-icon" alt="" />
            </div>
          </div>
        </div>
        <ActiveBlock
          v-if="activeBonus !== null"
          class="block"
          :bonus="activeBonus"
          :title="activeBonus.name"
          :expire="getActiveBonusExpireDate(activeBonus)"
          :value="`${activeBonus?.amount} ${activeBonus?.currency?.ticker}`"
          :icon="getIconByTicker(activeBonus?.currency?.ticker)"
        />

        <ActiveBlock
          v-for="freeBet in freeBets"
          :key="freeBet.id"
          :title="t('general.active_sport_free_bet')"
          :info="false"
          :expire="`${t('casino.expired_at')} ${moment(freeBet.expiredAt).format(
            'HH:mm DD/MM/YYYY'
          )}`"
          :value="freeBet.amount"
          :icon="getIconByTicker(freeBet?.currency?.ticker)"
          class="block"
        />
      </div>
    </div>
    <div v-if="selectedTab === 'deposit'" class="deposit">
      <div v-if="depositPersonalInfoRequirement" class="kyc-action">
        <p>{{ t("kyc.deposit_kyc_message") }}</p>
        <BaseButton @click="navigatePersonalInfo">
          {{ t("kyc.сlick_to_complete") }}
        </BaseButton>
      </div>
      <MoneyTransferSelection v-else type="deposit" />
    </div>
    <div v-if="selectedTab === 'withdraw'" class="withdraw">
      <div v-if="withdrawPersonalInfoRequirement" class="kyc-action">
        <p>{{ t("kyc.withdraw_kyc_message") }}</p>
        <BaseButton @click="navigatePersonalInfo">
          {{ t("kyc.сlick_to_complete") }}
        </BaseButton>
      </div>
      <div v-else-if="user?.kycWithdrawalBlocked" class="kyc-action">
        <p>{{ t("kyc.verify_identity_message") }}</p>
        <BaseButton @click="api.kyc.redirect()">
          {{ t("kyc.verify_identity") }}
        </BaseButton>
      </div>
      <div v-else-if="!user?.withdrawalAllowed" class="kyc-action">
        {{ t("finance.withdrawals_not_posible_message") }}
      </div>
      <MoneyTransferSelection v-else type="withdraw" />
    </div>
    <div v-if="selectedTab === 'transactions'">
      <Transaction />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currency-icon {
  width: 16px;
  height: 21px;
}
.wallet-balance {
  padding: 16px;
  background: var(--be-big-card-bg);
  border-radius: 8px;
  margin: 16px 0px;
  .balance-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .currency-balance {
    display: flex;
    gap: 6px;
    font-family: Poppins-reg;
    font-size: 14px;
    font-style: normal;
  }
}

.block {
  margin-bottom: 16px;
}
.container {
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  transform: translate(0px, -50%);
  top: 50%;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: var(--be-small-card-bg);
  border: 1px solid #333360;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: var(--be-small-card-bg);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--be-primary-btn);
  border: 1px solid var(--be-primary-btn);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5.5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid var(--be-primary-btn-label);
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.detail {
  border-bottom: 1px solid var(--be-small-card-bg);

  .wallets {
    display: flex;
    gap: 0 16px;
    justify-content: center;
    border-radius: 8px;
    background: var(--be-big-card-bg);
    padding: 10px;
    margin: 16px;
  }

  .wallet-tab {
    margin-bottom: 6px;
  }

  .wallet {
    color: rgba(255, 255, 255, 0.3);
    width: 122px;
    height: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 11px;
    cursor: pointer;
  }

  .active-wallet {
    color: var(--be-primary-btn-label);
    background: var(--be-primary-btn);
  }
  .detail-padding {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
  }
}

.balance {
  margin: 0 16px;
}

.detail-option-title {
  display: flex;
  gap: 0 8px;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}
h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 55px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 450px;
  margin-left: auto;
  // padding: 20px 20px;
  border-radius: 20px;
  background: #0d0c2c;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  margin: 0 16px;
  padding: 10px;
}

.account-detail__options {
  display: flex;
}

.deposit,
.withdraw {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  margin: 16px;
  padding: 16px;
}

.kyc-action {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
