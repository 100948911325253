<script setup>
import { ref, watch } from "vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import SlipCard from "../../../app/components/BetSlip/SlipCard.vue";
import { useBetStore } from "../../../../store";
import { useBetslip } from "@/sportsbook/composables/useBetslip.js";
import { useI18n } from "@/app/composables/useI18n";
import StakeField from "@/app/components/BetSlip/StakeField.vue";
import { placeBets } from "@/sportsbook/utils/betslipBetPlacement";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import { toast } from "@/app/utils/notification";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import moment from "moment";
import { useCurrencies } from "@/app/composables/useCurrencies";

const {
  betslipState,
  multipleBet,
  selections,
  hasMoreThanOneSelectionFromTheSameEvent,
  multipleStake,
  waitingForBetConfirmation,
  mobileBetslipVisible,
  selectedFreeBet,
  setFreeBets,
  updateOdds,
  selectFreeBet,
  save,
} = useBetslip();

const { activeWallet } = useUserService();

const delaySelection = ref([]);

const acceptChanges = ref(true);

const props = defineProps({
  mbl: Boolean,
  default: false,
});
const menuOpen = ref(true);
const singleTab = ref(false);

function open() {
  if (props.mbl) {
    const element = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
    if (selections.value.length > 0) {
      mobileBetslipVisible.value = !mobileBetslipVisible.value;
      element.style.overflow = "auto";
    }
  } else {
    menuOpen.value = !menuOpen.value;
  }
}

const { t } = useI18n();
const store = useBetStore();

const { activeFreeBets } = useFreeBets();
setFreeBets(activeFreeBets.value);

watch(activeFreeBets, async (newFreeBets, oldFreeBets) => {
  setFreeBets(newFreeBets, true);
});

watch(activeWallet, async (newActiveWallet, oldActiveWallet) => {
  if (newActiveWallet?.id !== oldActiveWallet?.id) {
    setFreeBets(activeFreeBets.value);
  }
});

function handleLogin() {
  store.handleShowLogin();
}

function callToast() {
  toast.error("Max 20 selections per bet");
}

const updateValue = (value) => {
  store.setTimeValue(value);
};

function hasLiveObject(arrayOfObjects) {
  for (let obj of arrayOfObjects) {
    if (obj.hasOwnProperty("is_live") && obj.is_live === true) {
      return true;
    }
  }
  return false;
}

const val = ref(false);
function handleSubmit(handleLogin, singleTab) {
  // if (hasLiveObject(selections.value)) {

  let flag = false;

  selections.value.map((item) => {
    if (!item.isActive) {
      flag = true;
    }
  });

  if (flag) {
    toast.error(
      "One or more selection(s) in the betslip are suspended. Remove selection(s) to place bet"
    );
  } else {
    store.setTimeValue(true);
    setTimeout(() => {
      placeBets(handleLogin, singleTab, acceptChanges.value);
    }, 2000);
  }
}

function handleAcceptChanges(handleLogin, singleTab) {
  let flag = false;
  selections.value.map((item) => {
    if (!item.isActive) {
      flag = true;
    }
  });

  if (flag) {
    toast.error(
      "One or more selection(s) in the betslip are suspended. Remove selection(s) to place bet"
    );
  } else {
    const objectById = store?.errorData?.newPrices?.reduce(function (obj, item) {
      obj[item?.oddId] = item;
      return obj;
    }, {});

    updateOdds(objectById);
    store.setTimeValue(3);
    store.setErrorData(null);
    placeBets(handleLogin, singleTab, acceptChanges.value);
  }
}

function handleClearBets() {
  if (props.mbl) {
    let element = document.querySelector(`.brand-${import.meta.env.VITE_APP_BRAND}`);
    element.style.overflow = "auto";
  }
  selections.value = [];

  mobileBetslipVisible.value = false;
  save();
}

// watch(
//   [() => store.timeValue],
//   async () => {
//     if (store.timeValue < 4 && store.timeValue > 0) {
//       showDelayedMessage();
//     } else if (store.timeValue == 0) {
//     }
//   },
//   { immediate: true }
// );

const disable = ref(false);

function hasInvalidStake(data) {
  return data.every(
    (item) => !("stake" in item) || item.stake === null || item.stake === "" || item.stake === 0
  );
}

setTimeout(() => {
  delaySelection.value = selections.value;
}, 0);

watch(
  selections,
  async (newValue, oldValue) => {
    delaySelection.value = selections.value;

    if (singleTab.value || selections.value.length == 1) {
      disable.value = hasInvalidStake(newValue);
    }

    if (selections.value.length == 0) {
      store.setTimeValue(false);
      store.setErrorData(false);
    }
  },
  { deep: true }
);
const handleAcceptChange = (e) => {
  acceptChanges.value = e.target.checked;
};

const { getIconByTicker } = useCurrencies();
</script>

<template>
  <div class="right-sec">
    <div class="betslip" @click="open">
      <div class="betslip-label">
        <img src="/assets/images/livebetting/betslip.svg" alt="" />
        <div>Betslip</div>
      </div>
      <img
        class="slip-arrow"
        :src="`/assets/images/${menuOpen ? 'bets/mybets/arrow-down.svg' : 'up-arrow.svg'}`"
        alt=""
      />
    </div>

    <div class="tabs" v-if="selections.length > 1 && menuOpen">
      <div :class="`bet-tab ${singleTab ? 'tab--active' : ''}`" @click="singleTab = true">
        Single
      </div>
      <div :class="`bet-tab ${!singleTab ? 'tab--active' : ''}`" @click="singleTab = false">
        Multiple
      </div>
    </div>
    <div
      v-if="selections.length >= 2 && !hasMoreThanOneSelectionFromTheSameEvent && menuOpen"
      class="clear-slip"
      @click="handleClearBets"
    >
      <img src="/assets/images/livebetting/delete.svg" alt="" />
      <div>{{ t("general.clear-bets") }}</div>
    </div>
    <div v-if="menuOpen" :class="`slips ${singleTab ? 'h--417' : 'h--330'}`">
      <SlipCard
        :singleTab="singleTab"
        v-for="(selection, index) in delaySelection"
        :key="selection.id"
        :index="index"
        :selection="selection"
      />
    </div>
    <div class="multiple-section">
      <div
        v-show="betslipState === 'active'"
        v-if="selections.length >= 2 && !hasMoreThanOneSelectionFromTheSameEvent && menuOpen"
      >
        <div v-if="!singleTab" class="multiple">
          <div class="multiple-label">
            {{ t("bets.multiples") }} ({{ selections.length }} {{ t("bets.selections") }})
          </div>
        </div>

        <div v-if="!singleTab" class="boxes">
          <div class="odd-box">
            <div>{{ t("bets.odds") }}</div>
            <div class="odd-digit">
              {{ multipleBet.odds }}
            </div>
          </div>
          <div class="odd-box">
            <div>{{ t("bets.potencial_win") }}</div>
            <div class="odd-digit">
              <div>{{ multipleBet.toWin.toFixed(2) }}</div>
              <img
                class="img-icon"
                v-if="getIconByTicker(activeWallet?.currency?.ticker) != ''"
                :src="getIconByTicker(activeWallet?.currency?.ticker)"
                alt=""
              />
            </div>
          </div>
          <div class="stake-box">
            <!-- <div>{{ t("bets.stake") }}</div> -->
            <StakeField
              v-model="multipleStake"
              :disabled="
                waitingForBetConfirmation || (selectedFreeBet && activeFreeBets.length > 0)
              "
              class="stake-field"
            />
          </div>
        </div>
      </div>
      <div class="slip-inputs" v-if="selections.length > 0">
        <div class="slip-input">
          <label class="container">
            <input type="checkbox" v-model="acceptChanges" @change="handleAcceptChange" />
            {{ t("bets.accept_changes") }}
            <span class="checkmark" />
          </label>
        </div>
      </div>
      <div class="slip-inputs" v-if="selections.length > 0">
        <div class="slip-input" v-if="activeFreeBets.length > 0">
          <label class="container">
            <input
              v-model="selectedFreeBet"
              type="checkbox"
              @change="selectFreeBet(activeFreeBets[0].id, activeFreeBets[0].amount)"
            />
            {{ t("casino.use_free_bet") }} ({{ activeFreeBets[0].currency.sign
            }}{{ activeFreeBets[0].amount }})
            <span class="checkmark" />
          </label>
        </div>
        <div class="expired-at" v-if="activeFreeBets.length > 0">
          <span>{{ t("casino.expired_at") }}&nbsp;</span>
          <span>{{ moment(activeFreeBets[0].expiredAt).format("DD/MM/YYYY") }}</span>
        </div>

        <div class="slip-input" v-if="false">
          <label class="container">
            <input type="checkbox" />
            Cut 1? You still win if 1 selection loses. Odds will be reduced to 1.40
            <span class="checkmark" />
          </label>
        </div>
      </div>

      <button
        v-if="menuOpen && selections.length != 0"
        :disabled="
          selections.length == 1 && disable
            ? true
            : singleTab && disable
            ? true
            : (multipleStake == '' || multipleStake == 0) && !singleTab && selections.length != 1
            ? true
            : false
        "
        class="btn-g"
        :class="
          selections.length == 1 && disable
            ? 'disabled'
            : singleTab && disable
            ? 'disabled'
            : (multipleStake == '' || multipleStake == 0) && !singleTab && selections.length != 1
            ? 'disabled'
            : ''
        "
        @click="
          () => {
            !store.timeValue && !store.errorData
              ? handleSubmit(handleLogin, singleTab)
              : store.errorData
              ? handleAcceptChanges(handleLogin, singleTab)
              : null;
          }
        "
      >
        {{
          !store.timeValue && !store.errorData
            ? t("bets.place_bet")
            : store.timeValue
            ? t("bets.placing_bet")
            : `${t("bets.accept_changes")} + ${t("bets.place_bet")}`
        }}
        <ClipLoader v-if="store.timeValue" :loading="true" color="white" size="20px" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.right-sec {
  width: 100%;
}

.disabled {
  opacity: 0.5;
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--be-big-card-bg);
  border-radius: 8px;
  height: 65px;
  padding: 10px;
  margin-bottom: 12px;
  .bet-tab {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 50%;
    color: #fff;
    opacity: 0.4;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  .tab--active {
    background: var(--be-primary-btn);
    color: var(--be-primary-btn-label);
    opacity: 1;
  }
}

.img-icon {
  width: 14px;
  height: 14px;
  margin: 2px 0 0 2px;
}

.slips {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.clear-slip {
  display: flex;
  gap: 0 15px;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  margin: 0 0 13px 10px;
}

.multiple {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px 0 8px 0;
}

// @media screen and (max-width: 500px) {
//   .right-sec {
//     display: none;
//   }
// }

.betslip {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  cursor: pointer;
  margin-top: 20px;

  .betslip-label {
    display: flex;
    gap: 0 6px;
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .slip-arrow {
    width: 16px;
  }
}

.boxes {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  .odd-box {
    border-radius: 8px;
    background: var(--be-big-card-bg);
    padding: 6px 16px 8px 16px;
    display: flex;
    width: 131px;
    height: 52px;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;

    .odd-digit {
      display: flex;
      color: var(--be-primary-btn);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      gap: 0 4px;
    }
  }
  .stake-box {
    border-radius: 4px;
    background: var(--be-small-card-bg);
    width: 119px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    padding-left: 10px;

    .stake-box-btc {
      background: #333360;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 52px;
      border-radius: 0 4px 4px 0;
    }
  }
}

.slip-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 13px;
  color: var(--White, #fff);

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expired-at {
  font-size: 10px;
  padding-left: 25px;
}

.slip-input {
  .container {
    display: block;
    position: relative;
    padding-left: 23px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--White, #fff);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    transform: translate(0px, -50%);
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    background-color: var(--be-small-card-bg);
    border: 1px solid #333360;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: var(--be-small-card-bg);
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: var(--be-primary-btn);
    border: 1px solid var(--be-primary-btn);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5.5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid var(--be-primary-btn-label);
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--be-primary-btn);
  margin: 12px 0px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 12px;
}

@media screen and (max-width: 500px) {
  .multiple-section {
    position: absolute;
    bottom: 0px;
    padding-right: 16px;
    width: calc(100% - 16px);
    background: var(--be-bg);
  }
  .betslip {
    margin-top: 8px;
  }

  .h--330 {
    height: 330px;
  }

  .h--417 {
    height: 417px;
  }

  .slips {
    overflow: auto;
  }
  .boxes {
    width: 100%;
    margin-top: 8px;
    justify-content: flex-start;
    gap: 8px;

    .stake-box {
      width: 33%;
    }

    .odd-box {
      width: 33%;
    }
  }
}
</style>
