<script setup>
import { computed } from "vue";

import LoginInfo from "./LoginInfo.vue";
import PersonalInfo from "./PersonalInfo.vue";
import Limits from "./Limits.vue";
import selfExclusion from "./selfExclusion.vue";

import { useBetStore } from "/store";
import BaseIcon from "@/app/components/ui/BaseIcon.vue";
import { t } from "@/app/composables/useI18n";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["close"]);
const store = useBetStore();

const tabs = [
  { value: "loginInfo", title: "login_info", icon: "edit-info" },
  { value: "personalInfo", title: "personal_info", icon: "kyc" },
  { value: "limits", title: "limits_self", icon: "limits" },
];

const iconBase = "/assets/images/bets/account-details/";

function onTabClick(tab) {
  store.setAccountTab(tab);
}

const selectedTab = computed(() => {
  return store.getAccountTab;
});
</script>

<template>
  <div class="account-details">
    <div class="account-details__options">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="`btn ${selectedTab === tab.value && 'active-btn'}`"
        @click="onTabClick(tab.value)"
      >
        <BaseIcon
          :name="tab.icon"
          size="17"
          :color="
            selectedTab === tab.value
              ? 'var(--be-primary-btn-text-label)'
              : 'var( --be-primary-text-color-opacity)'
          "
        />
        <!-- <img
          :src="`${iconBase + tab.icon}${selectedTab === tab.value ? '-active' : ''}.svg`"
          alt=""
        /> -->
        <div>{{ t(`general.${tab.title}`) }}</div>
      </div>
    </div>
    <div v-if="selectedTab === 'loginInfo'">
      <LoginInfo />
    </div>
    <div v-if="selectedTab === 'personalInfo'">
      <PersonalInfo />
    </div>
    <div v-if="selectedTab === 'limits'">
      <Limits />
      <selfExclusion />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-details {
  border-radius: 8px;
  margin: 0 16px;

  .account-details__options {
    background: var(--be-big-card-bg);
    border-radius: 8px;
    display: flex;
    padding: 10px;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 1rem;
    .btn {
      color: rgba(255, 255, 255, 0.3);
      display: flex;
      flex-direction: column;
      gap: 5px 0;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      cursor: pointer;
      width: 33%;
      padding: 9px 0;
      text-align: center;
    }
    .active-btn {
      border-radius: 6px;
      color: var(--be-primary-btn-label);
      background: var(--be-primary-btn);
      box-shadow: 0px 0px 10px 0px rgba(116, 62, 232, 0.5);
    }
  }
}
</style>
