import { computed, ref } from "vue";
import { api } from "@/services";

const currencies = ref([]);
const loading = ref(false);

export function useCurrencies() {
  async function loadCurrencies() {
    loading.value = true;
    currencies.value = await api.utils.listCurrencies();
    loading.value = false;
  }

  function setCurrencies(_currencies) {
    _currencies.forEach((currency) => {
      currency.icon = currency.ticker.toLowerCase();
    });
    currencies.value = _currencies;
  }

  function ticker(id: number) {
    const cc: Currency = currencies.value.find((c: Currency) => c.id === id);
    return cc ? cc.ticker : "Unknown";
  }

  function places(id: number) {
    const cc: Currency = currencies.value.find((c: Currency) => c.id === id);
    return cc.places;
  }

  function name(id: number) {
    const cc: Currency = currencies?.value?.find((c: Currency) => c?.id === id);
    return cc?.name;
  }

  function exchangeRate(id: number) {
    const cc: Currency = currencies.value.find((c: Currency) => c.id === id);
    return cc.exchangeRate;
  }

  function getDefaultCurrency() {
    return currencies.value.find((currency) => currency.default);
  }

  function currencyByTicker(ticker: string) {
    const cc: Currency = currencies.value.find((c: Currency) => c.ticker === ticker);
    return cc;
  }

  function getIconByTicker($ticker) {
    switch ($ticker) {
      case "USD":
        return "/assets/images/currencies/usd.svg";
      case "EUR":
        return "/assets/images/currencies/eur.svg";
      case "TRY":
        return "/assets/images/currencies/try.svg";
      case "BTC":
        return "/assets/images/currencies/btc-c.svg";
      case "ETH":
        return "/assets/images/currencies/eth-c.svg";
      case "USDTT":
        return "/assets/images/currencies/usdtt.svg";
      case "USDTE":
        return "/assets/images/currencies/usdte.svg";
      case "NGN":
        return "/assets/images/currencies/ngn.svg";
      default:
        return "";
    }
  }

  return {
    loading,
    currencies,
    loadCurrencies,
    setCurrencies,
    exchangeRate,
    name,
    currencyByTicker,
    ticker,
    places,
    getDefaultCurrency,
    getIconByTicker,
  };
}
