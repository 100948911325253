<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useBetStore } from "../../../../store";
import { useI18n } from "@/app/composables/useI18n";
import { setErrors, validate } from "@/user-account/utils/input-rules/validation";
import { api } from "@/services";
import PhoneRegistrationForm from "@/user-account/components/auth/PhoneRegistrationForm.vue";
import CodeVerificationForm from "@/user-account/components/auth/CodeVerificationForm.vue";
import { useAuth } from "@/user-account/composables/useAuth";
import { useUserService } from "@/user-account/composables/useUserService";

const props = defineProps({
  show: Boolean,
});

const emit = defineEmits(["openLoginModal", "close"]);

const { setToken } = useAuth();
const { setUser } = useUserService();

const router = useRouter();
const store = useBetStore();

const { t } = useI18n();
const requestId = ref();

const mode = computed(() => (requestId.value ? "verification" : "registration"));

async function register(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const payload = {
      countryCode: form[0].value,
      phone: form[1].value,
      password: form[2].value,
      referCode: form[3].value,
    };

    const btag = localStorage.getItem("btag");

    if (btag) {
      payload.btag = JSON.parse(btag);
    }

    const result = await api.auth.register(payload);

    if (result?.requestId) {
      requestId.value = result.requestId;
      store.setTime(getCurrentTimeInSeconds());
    }
  } catch (error) {
    setErrors(form, error.errors);
  }
}

const timeLockedForSeconds = ref(0);

async function verify(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const result = await api.auth.verifyCode({
      code: form[0].value,
      requestId: requestId.value,
    });

    if (result?.token) {
      setToken(result.token);
      setUser(result.user);
      emit("close", false);
      requestId.value = null;
      router.push({ path: "/" });
    } else if (result?.timeLockedForSeconds) {
      timeLockedForSeconds.value = result?.timeLockedForSeconds;
    }
  } catch (error) {
    setErrors(form, error?.errors);
  }
}

function openLogin() {
  emit("openLoginModal");
  emit("close", false);
}

async function resend() {
  const result = await api.auth.resendCode({ requestId: requestId.value });

  if (result?.requestId) {
    store.setTime(getCurrentTimeInSeconds());
    requestId.value = result.requestId;
  }
}

function getCurrentTimeInSeconds() {
  return Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-detail">
          <div
            class="close-btn"
            @click="
              () => {
                emit('close', false);
                if (mode === 'verification') {
                }
              }
            "
          >
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="">
          </div>

          <div class="modal-body">
            <PhoneRegistrationForm
              v-if="mode === 'registration'"
              @to-login="openLogin"
              @submit="register"
            />

            <CodeVerificationForm
              v-if="mode === 'verification'"
              :time-locked-for-seconds="timeLockedForSeconds"
              @submit="verify"
              @resend="resend"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

.modal-container {
  width: 491px;
  margin: auto;
  padding: 39px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  margin: auto;
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
