<script setup>
import { computed, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue";
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone";
import { rules as passwordRules } from "@/user-account/utils/input-rules/password";
import { rules as general } from "@/user-account/utils/input-rules/password/general";
import { rules as referCodeRules } from "@/user-account/utils/input-rules/refer-code";
import { api } from "@/services";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";

const emit = defineEmits(["submit", "toLogin"]);

const { referAndEarn } = useReferAndEarn();

const { t } = useI18n();

const countries = ref([]);

const inputs = ref([
  {
    name: "countryCode",
    value: "",
    type: "text",
  },
  {
    name: "phone",
    value: "",
    type: "tel",
    label: t("auth.phone"),
    placeholder: t("auth.phone"),
    rules: phoneRules,
    error: "",
  },
  {
    name: "password",
    value: "",
    type: "password",
    label: t("auth.password"),
    placeholder: t("auth.password"),
    rules: general,
    iconName: "eye-opened",
    error: "",
  },
  {
    name: "referCode",
    value: "",
    type: "string",
    label: t("auth.referCode"),
    placeholder: t("auth.referCode"),
    rules: referCodeRules,
    error: "",
  },
]);

function onLogin() {
  emit("toLogin");
}

const countryCodes = computed(() => {
  return countries.value.list?.filter((country) => country.status).map((country) => country.code);
});

async function fetchCountries() {
  countries.value = await api.utils.listCountries();
}

fetchCountries();
</script>

<template>
  <form @submit.prevent="$emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[1].value = $event"
        @country-changed="inputs[0].value = $event.iso2"
        @error="inputs[1].error = $event"
      />

      <BaseTextField
        :model-value="inputs[2].value"
        :rules="inputs[2].rules"
        :label="inputs[2].label"
        :placeholder="inputs[2].placeholder"
        :type="inputs[2].type"
        :icon-name="inputs[2].iconName"
        :error="inputs[2].error"
        @update:model-value="inputs[2].value = $event"
        @error="inputs[2].error = $event"
      />

      <BaseTextField
        v-if="referAndEarn.enabled"
        :model-value="inputs[3].value"
        :rules="inputs[3].rules"
        :label="inputs[3].label"
        :placeholder="inputs[3].placeholder"
        :type="inputs[3].type"
        :icon-name="inputs[3].iconName"
        :error="inputs[3].error"
        @update:model-value="inputs[3].value = $event"
        @error="inputs[3].error = $event"
      />
    </div>

    <div class="terms">
      <a href="/docs/t&c.pdf" target="_blank">
        {{ t("account.registration_age_term") }}
      </a>
    </div>

    <BaseButton class="submit alight wide high" type="submit">
      {{ t("auth.register") }}
    </BaseButton>

    <div class="dont-have-account">
      <p>
        {{ t("account.already_account_label") }}
      </p>
    </div>

    <button class="login-now" type="button" @click="onLogin">
      {{ t("account.login_now_label") }}
    </button>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.terms {
  font-size: 14px;
  margin-bottom: 1rem;
}

.login-now {
  text-align: center;
  color: var(--be-primary-btn);
  font-size: 14px;
  width: 100%;
}
.dont-have-account {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
